import React, { useEffect } from "react"
import "./video.scss"

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  useEffect(() => {
    // Update the document title using the browser API    document.title = `You clicked ${count} times`;
    console.log("props", props)
  })
  return (
    <div className="video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )
}
export default Video
