import React from "react"
import "./prices.scss"
import { Link } from "gatsby"

const Prices = () => {
  return (
    <div className="pricingdiv">
      <ul className="theplan" style={{ textAlign: "center" }}>
        <li className="title">
          <b>Mentale veerkracht</b>
        </li>
        <div className="content">
          <li>
            <b>Stress analyse</b>
          </li>
          <li>
            <b>eHealth modules</b>
          </li>
        </div>
        <li>
          <Link
            className="pricebutton"
            to="/contact"
            target="_blank"
            rel="nofollow"
          >
            <span className="icon-tag" />
            Neem contact op
          </Link>
        </li>
      </ul>
      <ul className="theplan" style={{ textAlign: "center" }}>
        <li className="title">
          <span className="icon-trophy" style={{ color: "red" }} />{" "}
          <b>Mentale veerkracht+</b>
        </li>
        <div className="content">
          <li>
            <b>Stress analyse</b>
          </li>
          <li>
            <b>eHealth modules</b>
          </li>
          <li>
            <b>Vitaliteitsscan</b>
          </li>
          <li>
            <b>Videocall</b>
          </li>
        </div>
        <li>
          <Link
            className="pricebutton"
            to="/contact"
            target="_blank"
            rel="nofollow"
          >
            <span className="icon-tag" /> Neem contact op
          </Link>
        </li>
      </ul>
      <ul className="theplan" style={{ textAlign: "center" }}>
        <li className="title">
          <b>Mentale veerkracht++</b>
        </li>
        <div className="content">
          <li>
            <b>Stress analyse</b>
          </li>
          <li>
            <b>eHealth modules</b>
          </li>
          <li>
            <b>Persoonlijkheidstest</b>
          </li>
          <li>
            <b>Intakegesprek</b>
          </li>
          <li>
            <b>5 persoonlijke sessies</b>
          </li>
        </div>
        <li>
          <Link
            className="pricebutton"
            to="/contact"
            target="_blank"
            rel="nofollow"
          >
            <span className="icon-tag" /> Neem contact op
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Prices
