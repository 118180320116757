import React from "react"
// import { Link } from "gatsby"

import "./index.scss"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Card from "../components/card"
import ContentImage from "../components/content-image"
import Breaker from "../components/breaker"
import Footer from "../components/footer"
import Prices from "../components/prices"
import Video from "../components/video"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

// import { airbnb } from '@fortawesome/free-solid-svg-icons'
// import { faAccusoft } from "@fortawesome/free-brands-svg-icons"
// import { faAngry } from "@fortawesome/free-regular-svg-icons"
import { faBullseye, faPollH, faBrain } from "@fortawesome/free-solid-svg-icons"

import ReactTextRotator from "react-text-rotator"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "case-edit.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coaching: file(relativePath: { eq: "begeleiding.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamontwikkeling: file(relativePath: { eq: "teamontwikkeling.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      verzuim: file(relativePath: { eq: "eenopeen.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = [
    {
      text: " bedrijven",
      className: "classA",
      animation: "fade",
    },
    {
      text: " sport",
      className: "classB",
      animation: "fade",
    },
    {
      text: " thuis",
      className: "classC",
      animation: "fade",
    },
  ]

  return (
    <Layout>
      <SEO title="Home" />
      <section id={"top"}>
        <div className="attention-box">
          <div className="topside">
            <h1>
              Zipperr voor
              <span style={{ minWidth: "50px" }}>
                <ReactTextRotator
                  content={content}
                  time={5000}
                  startDelay={0}
                />
              </span>
            </h1>

            <h5>Zipperr jij al?</h5>
          </div>
          <a
            className="cta"
            href={"tel:0653667567"}
            onClick={trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Home bel button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Home",
              // number - optional - Numeric value associated with the event. (e.g. A product ID)
              value: 100,
            })}
          >
            Bel voor een persoonlijk gesprek!
          </a>
        </div>
        {/* <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          objectFit="cover"
          // objectPosition="50% 50%"
          alt="Zipperr Case"
          style={{ maxHeight: "45%" }}
          className={"caseimg"}
        /> */}
        <Video
          videoSrcURL="https://www.youtube.com/embed/w-wpx1W5wys"
          videoTitle="Ontmoet zipperr"
        />
      </section>
      <section id={"services"} className={"flex"}>
        <Card icon={faBrain} title={"Breinkracht"} pagina={"coaching"}>
          <p>
            de Zipperr® Methode activeert jouw onderbewuste en legt een dusdanig
            fundament onder de gedragsverandering(en)...
          </p>
        </Card>
        <Card
          icon={faBullseye}
          title={"Effectiviteit"}
          pagina={"organisatieontwikkeling"}
        >
          <p>
            De Zipperr®Methode helpt organisaties om in 5 bijeenkomsten te komen
            tot duidelijke antwoorden op de vaak...
          </p>
        </Card>
        <Card
          icon={faPollH}
          title={"Resultaat"}
          pagina={"organisatieontwikkeling"}
        >
          <p>
            De Zipperr®Methode is een beproefde methodiek waarbij deelnemers...
          </p>
        </Card>
      </section>
      <section>
        <Breaker title={"WAT IS ZIPPERR®"}>
          <p
            style={{
              maxWidth: "80%",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          >
            Het Zipperr® programma is een no nonsense programma voor duurzame
            inzetbaarheid, bedoeld voor organisaties en individuen. Zipperen
            betekent het open en ook weer dichtritsen van problematiek.
          </p>
        </Breaker>
      </section>
      <section id={"info"}>
        <div className="container">
          <ContentImage
            title={"Ontwikkeling"}
            subTitle={"Zipperr® Methode voor teamontwikkeling"}
            img={
              <Img
                fluid={data.teamontwikkeling.childImageSharp.fluid}
                alt={"JW Player OTT Apps"}
                type="image/png"
                className="sbs-img overflow"
              />
            }
            pagina={"/teamontwikkeling"}
          >
            <p>
              Creëer de optimale werkomgeving met de gestructureerde aanpak van
              de Zipperr®Methode en laat je begeleiden middels een duurzaam en
              effectief Zipperr®abonnement.{" "}
            </p>
          </ContentImage>
          <ContentImage
            title={"Coaching"}
            subTitle={"Wat kun je verwachten van een professionele coach?"}
            img={
              <Img
                fluid={data.coaching.childImageSharp.fluid}
                alt={"JW Player OTT Apps"}
                type="image/png"
                className="sbs-img overflow"
              />
            }
            reverse={true}
            pagina={"/coaching"}
          >
            <p>
              Professionele coaching is een gestructureerd en doelgericht
              proces, waarbij de coach op interactieve wijze de gecoachte aanzet
              tot effectief gedrag.
            </p>
          </ContentImage>
        </div>
      </section>

      <section id={"prices"} style={{ marginBottom: 55 }}>
        <h3 style={{ textAlign: "center" }}>
          Kies voor een Zipperr® traject voor persoonlijke ontwikkeling en
          zakelijke groei
        </h3>
        <h4 style={{ textAlign: "center", color: "gray" }}>
          Neem contact op voor een van onze mogelijkheden!
        </h4>
        <Prices />
      </section>

      <Footer />
    </Layout>
  )
}

export default IndexPage
