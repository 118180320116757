import React, { useRef} from 'react';
import './footer.scss'
const Footer = () => {

  const bericht = useRef();
  const email = useRef();
  const name = useRef();

  const sendMessage = (ev) => {
    ev.preventDefault()
    const eBericht = encodeURI(bericht.current.value),
          eEmail = encodeURI(email.current.value),
          eName = encodeURI(name.current.value)
    window.open(`mailto:info@zipperr.nl?subject=Bericht%20van%20${eName}&body=${eBericht}%20mail%20terug%20naar%20${eEmail}`, '_blank');

    return false
  }

  return (
    <footer id="footer">
      <div className="inner">
        <h3>Kom in contact met ons via de mail!</h3>
        <form action="#" method="post">
          <div className="field half first">
            <label htmlFor="name">Naam</label>
            <input ref={name} name="name" id="name" type="text" placeholder="Naam"/>
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input ref={email} name="email" id="email" type="email" placeholder="Email"/>
          </div>
          <div className="field">
            <label htmlFor="message">Bericht</label>
            <textarea ref={bericht} name="message" id="message" rows="6" placeholder="Bericht"></textarea>
          </div>
          <ul className="actions">
            <li><input onClick={sendMessage} value="Verstuur bericht" className="button alt" type="submit"/></li>
          </ul>
        </form>
        <div className="copyright">
          © Zipperr. ~ Design: <a href="https://www.linkedin.com/in/oussama-elhajoui/">Oussama</a>
          <p style={{display: 'none'}}>Some Images: <a
            href="https://unsplash.com">Unsplash</a>.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
